import { createSlice } from "@reduxjs/toolkit";

export const getId = createSlice({
  name: "getID",
  initialState: [],
  reducers: {
    getID: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { getID } = getId.actions;

export default getId.reducer;

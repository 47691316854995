// ** React Imports
import React, { Fragment, useState, useEffect } from 'react';
import MapPicker from 'react-google-map-picker';
//import { Player, Controls } from '@lottiefiles/react-lottie-player'
import MapAnimation from './MapAnimation';

const MapPickerLocation = ({
	handleChangeLocation,
	location,
	zoom = 12,
	height = 400,
	width = '100%'
}) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setShow(true), 2500);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	if (!show) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<MapAnimation />
			</div>
		);
	}

	return (
		<React.Fragment>
			<MapPicker
				defaultLocation={location}
				zoom={zoom}
				mapTypeId="roadmap"
				style={{ height, width, borderRadius: 10 }}
				onChangeLocation={handleChangeLocation}
				//onChangeZoom={handleChangeZoom}
				apiKey="AIzaSyCjcN3Jn1fd52E-hJeDLu5pw5RC3sMbFcE"
			/>
		</React.Fragment>
	);
};

export default MapPickerLocation;

import { Player } from "@lottiefiles/react-lottie-player";

function Empty() {
  return (
    <Player
      autoplay
      loop
      src="/img/empty.json"
      style={{ height: "300px", width: "300px" }}
    ></Player>
  );
}

export default Empty;

import { configureStore } from "@reduxjs/toolkit";
import data from "./categories";
import getID from "./getId";
import counter from "./basketCounter";

export const store = configureStore({
  reducer: {
    data,
    getID,
    counter,
    devTools: false,
  },
});

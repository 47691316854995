import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// FRAMER MOTION
import { motion, AnimatePresence } from "framer-motion";
//REDUX
import { useSelector, useDispatch } from "react-redux";
import { getID } from "../redux/getId";

function Categories() {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);

  useEffect(() => {
    if (data) setLoading(true);
  }, [data]);

  const getProductID = (id) => {
    dispatch(getID(id));
  };

  return (
    <div className="p-4 grid gap-[18px] sm:gap-6 max-w-2xl m-auto">
      {loading
        ? data.map((item, i) => (
            <Link
              to={item.products.length === 0 ? "#" : "/products"}
              key={item.id}
              onClick={() => getProductID(item.id)}
              disabled
            >
              <AnimatePresence>
                <motion.div
                  className="flex"
                  initial={{
                    y: "20px",
                    opacity: 0,
                  }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    duration: 0.2,
                    delay: 0.2 + i / 5,
                  }}
                >
                  <img
                    src={item.media}
                    className="w-[69px] sm:w-[90px] h-[69px] sm:h-[90px] rounded-2xl"
                    alt={item.name}
                  />
                  <div className="pl-2 sm:pl-4 flex flex-col justify-center">
                    <h3 className="text-[17px] sm:text-xl font-medium dark:text-white">
                      {item.name}
                    </h3>
                    <span className="text-[#B6B6BA] text-xs sm:text-base font-normal mt-1.5">
                      {item.products_count} товары
                    </span>
                  </div>
                </motion.div>
              </AnimatePresence>
            </Link>
          ))
        : Array(3)
            .fill(0)
            .map((e, index) => (
              <div className="flex w-full items-center" key={index + 1}>
                <div className="animate-pulse flex w-full gap-2 sm:gap-4">
                  <div className="bg-[#d9d9d9] w-[69px] sm:w-[90px] h-[69px] sm:h-[90px] rounded-2xl" />
                  <div className="flex-1 space-y-6 py-1 flex flex-col justify-center">
                    <div className="space-y-3">
                      <div className="h-5 sm:h-7 bg-[#d9d9d9] rounded w-4/5" />
                      <div className="h-3 sm:h-5 bg-[#d9d9d9] rounded w-1/3" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
    </div>
  );
}

export default Categories;

import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { data } from "../redux/categories";

function Test({ items, width }) {
  const dispatch = useDispatch();

  const getData = (item) => {
    dispatch(data(item));
  };

  const style = {
    height: ` calc(${width}px / 3.5)`,
  };

  return (
    <>
      {items.map((item, i) => (
        <AnimatePresence key={item.id}>
          <motion.div
            key={item.id}
            className="cursor-pointer rounded-2xl overflow-hidden banner-img"
            style={style}
            initial={{
              y: "20px",
              opacity: 0,
            }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              duration: 0.2,
              delay: 0.2 + i / 5,
            }}
          >
            <Link to="/categories" onClick={() => getData(item.categories)}>
              <img src={item.media} alt="" className="w-full" />
            </Link>
          </motion.div>
        </AnimatePresence>
      ))}
    </>
  );
}

export default Test;

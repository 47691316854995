import instance from "./instance";

const API = {
  me: (props) => instance.get("/me", { params: props }),
  brands: () => instance.get("/brands"),
  addtoCart: (props) => instance.get("/add-to-cart", props),
};


export default API;

import { useRef, useEffect, useState } from "react";
import API from "./api";
import Banner from "./components/Banner";
import Empty from "./components/Empty";

export default function App() {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (process.env.REACT_APP_USER_ID === "prod") {
      window.Telegram.WebApp.MainButton.hide();
      localStorage.setItem(
        "userId",
        window.Telegram.WebApp.initDataUnsafe.user.id
      );
      localStorage.setItem("theme", window.Telegram.WebApp.colorScheme);
    } else {
      localStorage.setItem("userId", process.env.REACT_APP_USER_ID);
      localStorage.setItem("theme", "dark");
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: response } = await API.brands();
        setData(response.data);
      } catch (error) {
        console.error("response", error.message);
      }
      setLoading(false);
    };

    fetchData();

    // const authMe = async () => {
    //   setLoading(true);
    //   try {
    //     if (window) {
    //       const { data: response } = await API.me({
    //         telegram_user_id:
    //           process.env.REACT_APP_USER_ID === "prod"
    //             ? window.Telegram.WebApp.initDataUnsafe.user.id
    //             : process.env.REACT_APP_USER_ID,
    //       });
    //       localStorage.setItem("lang", response.data.lang);
    //       fetchData();
    //     }
    //   } catch (error) {
    //     console.error(error.message);
    //   }
    //   setLoading(false);
    // };

    // authMe();
  }, []);

  useEffect(() => {
    setWidth(ref.current.offsetWidth - 40);
  }, [width]);

  const style = {
    height: `calc(${width}px / 3.5)`,
  };

  return (
    <div
      className="grid gap-3 sm:gap-5 w-full p-5 max-w-2xl m-auto bg-white dark:bg-[#121618]"
      ref={ref}
    >
      {!loading && data.length === 0 ? (
        <Empty />
      ) : (
        <Banner items={data} width={width} />
      )}
      {loading &&
        Array(2)
          .fill(0)
          .map((e, index) => (
            <div className="animate-pulse" key={index}>
              <div style={style} className="bg-[#d9d9d9] rounded-2xl" />
            </div>
          ))}
    </div>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import Categories from "./pages/categories";
import Products from "./pages/products";
import Product from "./pages/productDetail";
import Basket from "./pages/basket";
// FRAMER MOTION
import { motion } from "framer-motion";
//REDUX
import { store } from "./redux/store";
import { Provider } from "react-redux";
//LAYOUT
import Layout from "./layout/layout";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter className="m-auto">
      <Layout>
        <motion.div
          className="container"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="categories" element={<Categories />} />
            <Route path="products" element={<Products />} />
            <Route path="/products/:productId" element={<Product />} />
            <Route path="basket" element={<Basket />} />
          </Routes>
        </motion.div>
      </Layout>
    </BrowserRouter>
  </Provider>
);
